$ionicons-font-path: "~ionicons/dist/fonts";
@import "~ionicons/dist/scss/ionicons.scss";

.open-icon--after:after {
  @extend .ion;
  content: "\f33b";
  margin-left: 0.5rem;
  font-size: 1rem;
}

.sort-dir--desc,
.sort-dir--asc  {
  white-space: nowrap;
}

.sort-dir--desc:after {
  @extend .ion-md-arrow-dropup, :before;
  margin-left: 0.5rem;
}
.sort-dir--asc:after {
  @extend .ion-md-arrow-dropdown, :before;
  margin-left: 0.5rem;
}
